import { useEffect } from 'react'
import { getGlobalState, setGlobalState, useGlobalState } from './GlobalState'

export default function useSocket() {
    const [, setTopics] = useGlobalState('topics')
    const [, setCountDownFlag] = useGlobalState('countDownFlag')
    const [, setTimerStartTime] = useGlobalState('timerStartTime')
    const [, setTimerSecondsRemaining] = useGlobalState('timerSecondsRemaining')
    const [, setTimerElapsedSeconds] = useGlobalState('elapsedSeconds')
    const [, setSortingDisabled] = useGlobalState('sortingDisabled')
    const [, setTimerLastStartedTime] = useGlobalState('lastStartedTime')
    const [, setDeleteAllCardsDisabled] = useGlobalState(
        'deleteAllCardsDisabled'
    )
    const [socket] = useGlobalState('socket')
    const [, setVotingStay] = useGlobalState('votingStay')
    const [, setVotingMoveOn] = useGlobalState('votingMoveOn')
    const [, setVoting] = useGlobalState('voting')
    const [, setSessionClientID] = useGlobalState('sessionClientID')

    const newTopicListener = () => {
        socket.on('newTopic', (newTopic) => {
            setTopics((topics) => topics.concat(newTopic))
            setDeleteAllCardsDisabled(false)
            setSortingDisabled(false)
        })
    }

    function refundVotes(topicId: string) {
        const client = getGlobalState('sessionClientID')
        const topicVotesBySessionID = getGlobalState('topics').find(
            (topic) => topic._id === topicId
        )?.votesBySessionID

        while (topicVotesBySessionID?.includes(client)) {
            const indexOfVote = topicVotesBySessionID.indexOf(client)
            topicVotesBySessionID.splice(indexOfVote, 1)
            setGlobalState('votesCast', getGlobalState('votesCast') - 1)
        }
    }

    function resetVotes() {
        setGlobalState('votesCast', 0)
    }

    const deleteTopicListener = () => {
        socket.on('deleteTopic', (topicId) => {
            refundVotes(topicId)
            setTopics((topics) => {
                return topics.filter((topic) => topic._id !== topicId)
            })
        })
    }

    const deleteAllListener = () => {
        socket.on('deleteAll', (updateTopic) => {
            resetVotes()
            setTopics(updateTopic)
            setDeleteAllCardsDisabled(true)
            setSortingDisabled(true)
        })
    }

    const updateTopicListener = () => {
        socket.on('updateTopic', (updatedTopic) => {
            setTopics((topics) => {
                return topics.map((topic) => {
                    if (topic._id === updatedTopic._id) {
                        return updatedTopic
                    }
                    return topic
                })
            })
        })
    }

    const sortTopicsListener = () => {
        socket.on('sortTopics', (sortedTopics) => {
            setTopics(sortedTopics)
        })
    }

    const startStopTimerListener = () => {
        socket.on('startStopTimer', (startStop, elapsedSeconds) => {
            if (startStop === 'Start') {
                setTimerLastStartedTime(new Date())
                setTimerElapsedSeconds(elapsedSeconds)
                setCountDownFlag(true)
                setSortingDisabled(true)
                setDeleteAllCardsDisabled(true)
            } else {
                setCountDownFlag(false)
            }
        })
    }

    const timerConnectionListener = () => {
        socket.on('timerConnection', (timerSeconds) => {
            setTimerSecondsRemaining(timerSeconds)
        })
    }

    const resetTimerListener = () => {
        socket.on('resetTimer', (initialSeconds) => {
            setTimerStartTime(initialSeconds)
            setCountDownFlag(false)
            setSortingDisabled(false)
            setDeleteAllCardsDisabled(false)
            setTimerSecondsRemaining(initialSeconds)
        })
    }

    const votingListener = () => {
        socket.on('voting', (vote) => {
            vote.action === 'stay'
                ? setVotingStay(vote.updatedVotes)
                : setVotingMoveOn(vote.updatedVotes)
        })
    }

    const endVotingListener = () => {
        socket.on('startEndVoting', (startEnd) => {
            if (startEnd === 'Start') {
                setVoting('voting')
            } else {
                setVoting('notVoting')
                setVotingStay(0)
                setVotingMoveOn(0)
            }
        })
    }

    const setSessionClientIDListener = () => {
        socket.on('connect', () => {
            setSessionClientID(socket.id!!)
        })
    }

    const setUpListeners = () => {
        newTopicListener()
        deleteTopicListener()
        updateTopicListener()
        sortTopicsListener()
        startStopTimerListener()
        timerConnectionListener()
        resetTimerListener()
        votingListener()
        endVotingListener()
        setSessionClientIDListener()
        deleteAllListener()
    }
    /* eslint-disable */
    useEffect(() => {
        setUpListeners()
        return () => {
            socket.close()
        }
    }, [])
    /* eslint-enable */
}
