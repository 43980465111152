import React from 'react'

export default function NoSSOMessage() {
    return (
        <>
            <div className="flexForm">
                <div className="box">
                    <div className="login-header">
                        <h1 className="title">Lean Latte</h1>
                        <div className={'center-align-column'}>
                            <p className="tag-line tag-line-top-margin">
                                Thank you for your interest in Lean Latte.
                                <br />
                                We are currently only providing access to Ford
                                users. Your interest has been noted and will
                                help influence future product decisions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
