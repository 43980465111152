import React from 'react'
import './TopicControls.scss'
import { useSortAllTopics, useDeleteAllTopics } from '../hooks/TopicHooks'
import { eventTrack } from '../../analytics/analytics-utils'
import { getGlobalState } from '../../state/GlobalState'
import QRCode from 'qrcode.react'

export default function TopicControls() {
    const [sortAllTopics] = useSortAllTopics()
    const [deleteAllTopics] = useDeleteAllTopics()

    return (
        <div className="topic-controls">
            <div data-testid="sort-topics">
                <button
                    disabled={getGlobalState('sortingDisabled')}
                    data-testid="sortButton"
                    onClick={() => {
                        sortAllTopics()
                        eventTrack('Room page', 'Sort topics', 'Button')
                    }}
                    className={'sort-button'}
                >
                    Sort Cards
                </button>
            </div>
            <div data-testid="delete-topics">
                <button
                    disabled={getGlobalState('deleteAllCardsDisabled')}
                    data-testid="deleteButton"
                    onClick={() => {
                        deleteAllTopics()
                        eventTrack('Room page', 'Delete topics', 'Button')
                    }}
                    className={'sort-button delete-all-button'}
                >
                    Delete All Cards
                </button>
            </div>
            <QRCode
                role={'link'}
                title={'QR Code'}
                className={'qr-code'}
                value={
                    window.location.href
                        ? window.location.href
                        : 'https://leanlatte.ford.com/'
                }
            />
        </div>
    )
}
