import { useEffect, useState } from 'react'
import { useGlobalState } from '../../state/GlobalState'
import { Room } from '../../models/room'
import { fetchWithAuth } from '../../utils/authHelper'
type RoomResponse = {
    data: Room | null
    loading: boolean
    error: any
}
function useRoom(): [
    RoomResponse,
    (url: string, config: RequestInit | undefined) => Promise<void>
] {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    const exec = (url: string, config: RequestInit | undefined) => {
        setLoading(true)
        setError(null)
        setData(null)
        return fetchWithAuth(url, config)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('could not fetch room')
                }
                res.json()
                    .then((data) => {
                        setData(data)
                    })
                    .catch((err) => setError(err))
            })
            .catch((err) => {
                setError(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return [{ data, loading, error }, exec]
}

export function useFetchRoom(): [RoomResponse, () => void] {
    const [result, exec] = useRoom()
    const [socket] = useGlobalState('socket')
    const [roomName] = useGlobalState('roomName')

    const fetchRoom = () => {
        // @ts-ignore
        exec(`${process.env.REACT_APP_API_URL}/api/room/${roomName}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
    }

    useEffect(() => {
        if (roomName !== '') {
            fetchRoom()
            socket.emit('joinRoom', roomName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomName])

    return [result, fetchRoom]
}
