import { useMsalAuthentication, useMsal } from '@azure/msal-react'
import { InteractionType, RedirectRequest } from '@azure/msal-browser'
import { loginRequest, msalInstance } from './msalConfig'
export async function fetchWithAuth(
    path: RequestInfo,
    userOptions = { method: 'GET' } as RequestInit
) {
    const account = msalInstance.getActiveAccount()

    if (!account) {
        throw Error(
            'No active account! Verify a user has been signed in and setActiveAccount has been called.'
        )
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
    })
    const headers = new Headers()
    const bearer = `Bearer ${response.accessToken}`

    headers.append('Authorization', bearer)

    const options = {
        ...userOptions,
        headers,
    }
    return fetch(path, options)
}

export function useAuthHelper() {
    const { instance } = useMsal()
    const account = instance.getActiveAccount() ?? undefined

    const request: RedirectRequest = {
        scopes: ['api://d7b6a4b3-3bba-4bd5-bc19-863e428621ae/user'],
        account,
    }
    useMsalAuthentication(InteractionType.Redirect, {
        ...request,
    })
}
