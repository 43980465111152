import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from '../../state/GlobalState'
import './Login.scss'
import { eventTrack } from '../../analytics/analytics-utils'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react'
import { fetchWithAuth, useAuthHelper } from '../../utils/authHelper'

// import { callApigeeWithAuth } from '../../utils/api'

function AuthAndLoadMain() {
    useAuthHelper()
    return <></>
}

function Login() {
    const [, setRoomName] = useGlobalState('roomName')
    const [room, setRoom] = useState({ roomName: '' })
    const history = useHistory()
    const [iAmFord, setIAmFord] = useState(false)
    // const [status, setStatus] = useState('ERROR')

    const adjustRoomName = () => {
        return { roomName: room.roomName.replaceAll(' ', '-').toLowerCase() }
    }

    const createNewRoom = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const adjustedRoomName = adjustRoomName()
        setRoomName(adjustedRoomName.roomName)
        fetchWithAuth(
            `${process.env.REACT_APP_API_URL}/api/room/${adjustedRoomName.roomName}`,
            {
                method: 'POST',
            }
        ).then(() => {
            history.push(`/room/${adjustedRoomName.roomName}`)
            eventTrack('Login page', 'Create new room', 'Button')
        })
    }

    function handleNoSSOClick() {
        return () => {
            eventTrack('Login page', 'No SSO', 'Button')
            history.push('/no-sso')
        }
    }

    return (
        <>
            <div className="flexForm">
                <div className="box">
                    <div className="login-header">
                        <h1 className="title">Lean Latte</h1>
                        <AuthenticatedTemplate>
                            <p className="tag-line">
                                Suggest some topics, vote your favorites,
                                discuss. Drink coffee.
                            </p>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <div className={'center-align-column'}>
                                <p className="tag-line">
                                    This application is only available through
                                    Ford SSO.
                                </p>
                                <button
                                    data-testid="sso-button"
                                    onClick={() => {
                                        eventTrack(
                                            'Login page',
                                            'Has SSO',
                                            'Button'
                                        )
                                        setIAmFord(true)
                                    }}
                                    className="button child sign-in-button"
                                >
                                    Sign in with SSO
                                </button>
                            </div>
                        </UnauthenticatedTemplate>
                    </div>
                    <UnauthenticatedTemplate>
                        <div className={'center-align-column'}>
                            <p className="tag-line">
                                Don't have Ford SSO Permissions? <br />
                                Click here and let us know!
                            </p>
                            <button
                                data-testid="no-sso-button"
                                className="button child sign-in-button"
                                onClick={handleNoSSOClick()}
                            >
                                I don't have Ford SSO
                            </button>
                        </div>
                        {iAmFord && <AuthAndLoadMain></AuthAndLoadMain>}
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <form onSubmit={createNewRoom}>
                            <input
                                required
                                type="text"
                                data-testid="createRoomName"
                                value={room.roomName}
                                placeholder="Type a name for your room"
                                className="child create-input"
                                onChange={(event) =>
                                    setRoom({
                                        ...room,
                                        roomName: event.target.value,
                                    })
                                }
                            />
                            <button
                                className="button child create-button"
                                type="submit"
                                data-testid="createRoomButton"
                            >
                                Create
                            </button>
                        </form>
                    </AuthenticatedTemplate>
                </div>
            </div>
        </>
    )
}

export default Login
