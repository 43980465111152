import {
    Configuration,
    PopupRequest,
    PublicClientApplication,
} from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: 'd7b6a4b3-3bba-4bd5-bc19-863e428621ae',
        authority:
            'https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0',
        redirectUri: '/',
        // postLogoutRedirectUri: '/',
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    },
    cache: {
        temporaryCacheLocation: 'sessionStorage',
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
        secureCookies: true,
    },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['api://d7b6a4b3-3bba-4bd5-bc19-863e428621ae/user'],
}
export const msalInstance = new PublicClientApplication(msalConfig)
